<template>
    <el-table v-if="data.length > 0" :data="data">
        <el-table-column width="55">
            <template slot-scope="scope">
                <el-popover
                    v-if="scope.row.avatar_src"
                    placement="top-start"
                    width="250"
                    trigger="hover"
                >
                    <div class="h-56 w-56 rounded-full overflow-hidden">
                        <img :src="scope.row.avatar_src" class="h-auto w-full">
                    </div>
                    <img slot="reference" :src="scope.row.avatar_src" class="h-8 w-8 rounded-full object-cover">
                </el-popover>
            </template>
        </el-table-column>
        <el-table-column :label="$t('employees.name')" prop="name" sortable :show-overflow-tooltip="true">
            <template slot-scope="scope">
                <router-link :to="`/employees/${scope.row.uuid}/information`" class="hover:text-red-500 hover:font-semibold">
                    {{ nameFormatter(scope.row) }}
                </router-link>
            </template>
        </el-table-column>
        <el-table-column :label="$t('employees.street')" prop="address.street" sortable :show-overflow-tooltip="true" />
        <el-table-column :label="$t('employees.city')" prop="address.city" sortable />
        <el-table-column :label="$t('employees.email')" prop="email" sortable width="300" />
        <el-table-column :label="$t('employees.telephone')" prop="telephone" sortable width="150" />
        <el-table-column :label="$t('employees.manager')" prop="manager" sortable :show-overflow-tooltip="true" />
        <el-table-column width="75">
            <template slot-scope="scope">
                <el-button-group>
                    <el-tooltip :enterable="false" :openDelay="500" effect="dark" :content="$t('common.details')" placement="left">
                        <router-link
                            :to="`/employees/${scope.row.uuid}/information`"
                            class="el-button el-tooltip el-button--primary el-button--mini"
                        >
                            <fa-icon :icon="['fas', 'info']" fixed-width />
                        </router-link>
                    </el-tooltip>
                    <el-tooltip :enterable="false" :openDelay="500" effect="dark" :content="$t('employees.archive')" placement="top">
                        <el-button type="warning" size="mini" @click="handleArchive(scope.row.uuid)">
                            <fa-icon :icon="['fas', 'archive']" fixed-width />
                        </el-button>
                    </el-tooltip>
                </el-button-group>
            </template>
        </el-table-column>
    </el-table>
</template>
<script>
export default {
    props: {
        data: {
            type: Array,
            default() { return []; },
        },
    },

    methods: {
        handleArchive(uuid) {
            this.$emit('handleArchive', uuid);
        },

        nameFormatter(row) {
            return `${row.name} ${row.surname}`;
        },
    },
};
</script>
